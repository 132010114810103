const initState = {
    loading: false,
    loadError: false,
    errorMsg: '',
    errorMsgData: '',
    config: {}
}

const loadRemoteConfigReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOAD_REMOTE_CONFIG_STARTED':
            return {
                ...initState,
                loading: true
            }
        case 'LOAD_REMOTE_CONFIG_COMPLETED':
            return {
                ...initState,
                config: action.payload
            }
        case 'LOAD_REMOTE_CONFIG_ERROR':
            const { errorMsg, errorMsgData } = action.payload;
            return {
                ...initState,
                loadError: true,
                errorMsg,
                errorMsgData
            }
        default:
            return state;
    }
}

export default loadRemoteConfigReducer;