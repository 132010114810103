//Core imports
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

//Main navigation releated imports
import MainNav from './components/common/MainNav';

import { mainNavConfig } from './config/navConfig';

//Layout imports
import Home from './components/layouts/home';
import Customers from './components/layouts/customers'
import EPartner from './components/layouts/epartner';
import Access from './components/layouts/access'
import Users from './components/layouts/users';
import MalVerify from './components/layouts/malverify'

//Import action creators
import { loadRemoteConfig } from './store/actions/configActions';

class App extends Component {

  componentDidMount() {
    this.props.loadRemoteConfig();
  }

  render() {

    //Protectig the component to prevent access without login
    const { auth, config } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />

    if(config && config.loadError){
      return(
        <Route path='/' component={() => <Home config={config} />} exact />
      )
    }

    return (
      <Container fluid>
        <MainNav mainNavConfig={mainNavConfig} />
        {/* Define all main app components here */}
        <Container fluid style={{ padding: '10px 30px' }}>
          <Switch>
            <Route path='/' component={Home} exact />
            <Route path='/customers' component={Customers} />
            <Route path='/epartner' component={EPartner} />
            <Route path='/access' component={Access} />
            <Route path='/users' component={Users} />
            <Route path='/malverify' component={MalVerify} />
          </Switch>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Global state logged from App componenet', state);
  return {
    auth: state.firebase.auth,
    config: state.config
  }
}


export default compose(
  connect(mapStateToProps, {
    loadRemoteConfig
  }),
  firestoreConnect(props => [
    //Loading user role to manage access. 
    //This will be stored in global state as firestore.data.accessRole
    //Any component can get the access rules by mapping state to props from above location
    { collection: 'roles', doc: props.role, storeAs: 'accessRole' }
  ])
)(App);
