import React, { Component } from 'react';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

class DataTable extends Component {
    componentDidMount() {
        this.$el = $(this.el);
        this.$el.DataTable({
            dom: '<"data-table-wrapper"lfrtip>',
            data: this.props.data,
            columns: this.props.columns,
            ordering: false,
            scrollX: true
        })
    }

    componentWillUnmount() {
        $('.data-table-wrapper').find('table').DataTable().destroy(true);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.data.length !== this.props.data.length) {
            this.reloadTableData(nextProps.data);
        }
        return false;
    }

    reloadTableData = (data) => {
        const table = $('.data-table-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    render() {
        return (
            <div style={{marginBottom: '5%'}}>
                <style>
                    {`
                    table.dataTable tbody td {
                        padding: 3px 3px!Important;
                        font-size: smaller;
                    }
                    .dataTables_scroll{
                        padding-top: 10px;
                    }
                    `}
                </style>
                <table
                    className='ui celled table'
                    id='dataTable'
                    width='100%'
                    style={{
                        cellPadding: 0,
                        cellSpacing: 0,
                    }}
                    ref={(el) => (this.el = el)}
                />
            </div>
        )
    }
}

export default DataTable;