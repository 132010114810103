import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { loadMalVerifyRecords } from '../../../store/actions/malListActions';
import DataTable from '../../common/DataTable';


const columns = [
    { title: 'Partner Company ID', data: 'partnerCompany' },
    { title: 'Issued Date', data: 'issueDate' },
    { title: 'Tender Name', data: 'tenderName' },
    { title: 'Tender Referrence', data: 'tenderRefNo' },
    { title: 'Address To', data: 'addressTo' },
    { title: 'Verification ID', data: 'id' },
    { title: 'Verification', data: 'qr' },
]

class MalList extends Component {
    componentDidMount() {
        this.props.loadMalVerifyRecords();
    }

    refreshReport = () => {
        this.props.loadMalVerifyRecords();
    }

    render() {
        const { loading, loadError, errorMsg, errorMsgData, malListData } = this.props.malList;

        return (
            <Segment loading={loading}>
                <Header as='h3'>
                    <Icon name='th list' />
                    <Header.Content>
                        All MAL Records
                    </Header.Content>
                </Header>
                <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={() => this.refreshReport()} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
                {
                    loadError &&
                    <Message negative>
                        <Message.Header>Error loading MAL records</Message.Header>
                        <p>{errorMsg}</p>
                        <p>{errorMsgData}</p>
                    </Message>
                }
                {
                    (Array.isArray(malListData) && malListData.length > 0) ?
                    <DataTable data={malListData} columns={columns} />
                    :
                    <p>No records available</p>
                }
                
            </Segment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        malList: state.malList,
    }
}

export default connect(mapStateToProps, { loadMalVerifyRecords })(MalList);