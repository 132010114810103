import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { loadCustomerData } from '../../../store/actions/customersActions';
import DataTable from '../../common/DataTable';


const columns = [
    { title: 'Company Code', data: 'id' },
    { title: 'Company Name', data: 'companyName' },
    { title: 'Company Type', data: 'companyTypeText' },
    { title: 'Country', data: 'countryText' },
    { title: 'Purchase From Company ID', data: 'purchaseCompany' },
    { title: 'Primary Contact Person', data: 'primaryContactPerson' },
    { title: 'Primary Contact Number', data: 'primaryContactNumber' },
    { title: 'Primary Email', data: 'primaryEmail' },
    { title: 'Permitted Apps', data: 'appPermissionText' },
]

class CustomersList extends Component {
    componentDidMount() {
        this.props.loadCustomerData();
    }

    refreshReport = () => {
        this.props.loadCustomerData();
    }

    render() {
        const { loading, loadError, errorMsg, errorMsgData, customerData } = this.props.customers;

        return (
            <Segment loading={loading}>
                <Header as='h3'>
                    <Icon name='th list' />
                    <Header.Content>
                        All Customers
                    </Header.Content>
                </Header>
                <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={() => this.refreshReport()} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
                {
                    loadError &&
                    <Message negative>
                        <Message.Header>Error loading customer data</Message.Header>
                        <p>{errorMsg}</p>
                        <p>{errorMsgData}</p>
                    </Message>
                }
                {
                    (Array.isArray(customerData) && customerData.length > 0) ?
                    <DataTable data={customerData} columns={columns} />
                    :
                    <p>No records available</p>
                }
                
            </Segment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customers: state.customers
    }
}

export default connect(mapStateToProps, { loadCustomerData })(CustomersList);