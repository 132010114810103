import React, { Component } from 'react';
import {
    Header,
    Icon,
    Segment,
    Message
} from 'semantic-ui-react';


class Home extends Component {

    render() {
        const { config } = this.props;


        return (
            <Segment placeholder loading={config ? config.loading : false}>
                <Header icon>
                    <Icon name='settings' />
                    Welcome to the Hik-Portal Managment Console
                    <Header.Subheader>
                        This module is for internal use only. This can be manage Hik-Portal.
                    </Header.Subheader>
                </Header>

                {
                    (config && config.loadError) &&
                    <Message negative>
                        <Message.Header>{config.errorMsg}</Message.Header>
                        <p>{config.errorMsgData}</p>
                    </Message>
                }

            </Segment>
        )
    }
}

export default Home;
