const initState = {
    loading: false,
    loadError: false,
    errorMsg: '',
    errorMsgData: '',
    malListData: []
}

const malListReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_MAL_VERIFY_RECORDS_STARTED':
            return {
                ...initState,
                loading: true
            }
        case 'LOAD_MAL_VERIFY_RECORDS_SUCCESS':
            return {
                ...initState,
                malListData: action.payload
            }
        case 'LOAD_MAIL_VERIFY_RECORDS_FAILED':
            const { errorMsg, errorMsgData } = action.payload;
            return {
                ...initState,
                loadError: true,
                errorMsg,
                errorMsgData
            }
        default:
            return state;
    }
}

export default malListReducer;