import QR from 'qrcode-base64'


export const loadMalVerifyRecords = () => {
    return(dispatch, getState, {getFirestore}) => {
        dispatch({type: 'LOAD_MAL_VERIFY_RECORDS_STARTED'})

        getFirestore().collection('malverify').get()
        .then((querySnapshot)=>{

            const malVerifyRecords = [];

            querySnapshot.forEach(doc=>{
                const {
                    partnerCompany,
                    issueDate,
                    tenderName,
                    tenderRefNo,
                    addressTo,
                    country
                } = doc.data();

                const {malVerifyURLPrefix} = getState().config.config;
                const malVerifyLink  = `${malVerifyURLPrefix}${doc.id}`;

                const qr = QR.drawImg(malVerifyLink, {
                    typeNumber: 4,
                    errorCorrectLevel: 'M',
                    size: 100
                  })

                const malVerifyRecord = {
                    id: doc.id,
                    partnerCompany,
                    issueDate,
                    tenderName,
                    tenderRefNo,
                    addressTo,
                    country,
                    qr: `<div style='margin: 10px'><img src='${qr}'/><br><a href='${malVerifyLink}' target="_blank">${malVerifyLink}</a></div>`
                }

                return malVerifyRecords.push(malVerifyRecord);
            })

            console.log('malVerifyRecords', malVerifyRecords)

            dispatch({
                type: 'LOAD_MAL_VERIFY_RECORDS_SUCCESS',
                payload: malVerifyRecords
            })
        })
        .catch(err=>{
            dispatch({
                action: 'LOAD_MAIL_VERIFY_RECORDS_FAILED',
                payload: {
                    errorMsg: 'Cannot retrieve MAL records from the database. Please contact system administrator',
                    errorMsgData: err.toString()
                }
            })
        })
    }
}