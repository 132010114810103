import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom'

import SideNav from '../../common/SideNav';
import { customersNavConfig } from '../../../config/navConfig';

import CustomersList from './CustomersList';
import CustomerModal from './CustomerModal';

class Customers extends Component {
    render() {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Column stretched width={2}>
                        <SideNav sideNavConfig={customersNavConfig}>
                            <CustomerModal trigger='add' />
                        </SideNav>
                    </Grid.Column>

                    <Grid.Column stretched width={14}>
                        <Switch>
                            <Route path='/customers' component={CustomersList} exact />
                        </Switch>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default Customers;
