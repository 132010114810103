import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { loadGiftData } from '../../../store/actions/gifsListActions';
import DataTable from '../../common/DataTable';

const columns = [
    { title: 'Ship ID', data: 'ShipID' },
    { title: 'Created Date', data: 'CreatedDate' },
    { title: 'Company Name', data: 'CompanyName' },
    { title: 'OneHikID', data: 'OneHikID' },
    { title: 'Shipping Name', data: 'ShippingName' },
    { title: 'Shipping Address', data: 'ShippingAddress' },
    { title: 'Shipping Email', data: 'ShippingEmail' },
    { title: 'Shipping Phone', data: 'ShippingPhone' },
    { title: 'Gift Name', data: 'GiftName' },
    { title: 'Unit Price', data: 'UnitPrice' },
    { title: 'Redeem Qty', data: 'RedeemQTY' },
    { title: 'Claim Status', data: 'ClaimStatus' },
    { title: 'Claimed Time', data: 'claimedTime' },
    { title: 'Claimed Customer ID', data: 'claimedCustomerID' },
    { title: 'Claimed User ID', data: 'claimedUserID' },
    { title: 'Claimed Value', data: 'totalClaimedValue' },
    { title: 'Rebate Value', data: 'totalRebateValue' },
    { title: 'Last Claim Requested Customer ID', data: 'lastRequestCustomerID' },
    { title: 'Last Claim Requeste User ID', data: 'lastRequestUserID' },
]

class GiftsList extends Component {
    componentDidMount() {
        this.props.loadGiftData();
    }

    refreshReport = () => {
        this.props.loadGiftData();
    }

    render() {
        const { loading, loadError, errorMsg, errorMsgData, giftData } = this.props.giftsList;

        return (
            <Segment loading={loading}>
                <Header as='h3'>
                    <Icon name='th list' />
                    <Header.Content>
                        Gifts List
                    </Header.Content>
                </Header>
                <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={() => this.refreshReport()} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
                {
                    loadError &&
                    <Message negative>
                        <Message.Header>Error loading gift data</Message.Header>
                        <p>{errorMsg}</p>
                        <p>{errorMsgData}</p>
                    </Message>
                }
                {
                    (Array.isArray(giftData) && giftData.length > 0) ?
                    <DataTable data={giftData} columns={columns} />
                    :
                    <p>No records available</p>
                }
                
            </Segment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        giftsList: state.giftsList
    }
}

export default connect(mapStateToProps, { loadGiftData })(GiftsList);