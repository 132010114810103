import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Menu, Button, Icon, Message, Statistic, Progress, Table, Header, List, Container } from 'semantic-ui-react';
import { FilePond } from 'react-filepond';


import 'filepond/dist/filepond.min.css'

import excelTemplate from '../../../resources/Template.zip'

import { uploadGifts, uploadGiftsReset } from '../../../store/actions/giftUploadActions';

const initState = {
    modalOpen: false,
    files: [],
    fileSelected: false
}

class UploadGifts extends Component {

    state = initState;

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.props.uploadGiftsReset();
        this.setState(initState);
    }

    handleSubmit = () => {
        this.props.uploadGifts(this.state.files[0])
    }


    render() {
        const { files, fileSelected, modalOpen } = this.state;

        const {
            processing,
            totalCount,
            processedCount,
            failiureCount,
            failedRecords,
            uploadCompleted,
            uploadError,
            errorMsg,
            errorMsgData,
        } = this.props.giftsUpload;


        return (
            <Container>
                <Modal
                    trigger={<Menu.Item name='Upload Gift List' onClick={this.handleOpen} />}
                    open={modalOpen}
                    onClose={this.handleClose}
                    size='large'
                    closeOnDimmerClick={false}
                >
                    {
                        processing || uploadCompleted ?
                            <Fragment>
                                <Progress percent={(processedCount / totalCount) * 100} indicating attached='top' />
                                <Modal.Header>
                                    Upload Gift List: {processing ? 'In Progress' : 'Completed'}

                                </Modal.Header>
                                <Modal.Header>
                                    <Statistic.Group widths='four'>

                                        <Statistic color='blue'>
                                            <Statistic.Value>{totalCount}</Statistic.Value>
                                            <Statistic.Label>Total Records</Statistic.Label>
                                        </Statistic>
                                        <Statistic color='yellow'>
                                            <Statistic.Value>{processedCount}</Statistic.Value>
                                            <Statistic.Label>Processed</Statistic.Label>
                                        </Statistic>
                                        <Statistic color='green'>
                                            <Statistic.Value>{processedCount - failiureCount}</Statistic.Value>
                                            <Statistic.Label>Uploaded</Statistic.Label>
                                        </Statistic>
                                        <Statistic color='red'>
                                            <Statistic.Value>{failiureCount}</Statistic.Value>
                                            <Statistic.Label>Failed</Statistic.Label>
                                        </Statistic>
                                    </Statistic.Group>
                                </Modal.Header>
                                {
                                    (Array.isArray(failedRecords) && failedRecords.length > 0) &&
                                    <Modal.Content scrolling>
                                        <Header as='h3' color='red'>
                                            Failed Records
                                    </Header>
                                        <Table celled padded>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell width={1}>ShipID</Table.HeaderCell>
                                                    <Table.HeaderCell width={3}>CompanyName</Table.HeaderCell>
                                                    <Table.HeaderCell width={3}>ShippingEmail</Table.HeaderCell>
                                                    <Table.HeaderCell width={2}>GiftName</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>UnitPrice</Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>RedeemQTY</Table.HeaderCell>
                                                    <Table.HeaderCell width={4}>Errors</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {
                                                    failedRecords && failedRecords.map((failedRecord, index) => {
                                                        const { ShipID, CompanyName, ShippingEmail, GiftName, UnitPrice, RedeemQTY, foundErrors } = failedRecord;
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>
                                                                    <Header as='h2' textAlign='center'>
                                                                        {ShipID}
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>{CompanyName}</Table.Cell>
                                                                <Table.Cell>
                                                                    {ShippingEmail}
                                                                </Table.Cell>
                                                                <Table.Cell>{GiftName}</Table.Cell>
                                                                <Table.Cell textAlign='right'>
                                                                    {UnitPrice}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='right'>
                                                                    {RedeemQTY}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <List bulleted>
                                                                        {foundErrors && foundErrors.map((foundError, index) => {
                                                                            return <List.Item key={index}>{foundError}</List.Item>
                                                                        })}

                                                                    </List>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Modal.Content>
                                }
                                {uploadCompleted &&
                                    <Modal.Actions>
                                        <Button onClick={this.handleClose}>
                                            <Icon name='remove' /> Close
                                    </Button>
                                    </Modal.Actions>
                                }
                            </Fragment>
                            :
                            <Fragment>
                                <Modal.Header>Upload Gift List</Modal.Header>
                                <Modal.Content>
                                    <Message>
                                        <Message.Header>Important</Message.Header>
                                        <Message.List>
                                            <Message.Item>Supported file type is CSV</Message.Item>
                                            <Message.Item>The columns 'ShipID', 'CreatDate', 'OneHikID', 'ShippingEmail', 'GiftName', 'UnitPrice' and 'RedeemQTY' are mandatory</Message.Item>
                                            <Message.Item>Please format the data using the Excel template before importing. Click <a href={excelTemplate}>here</a> to download the Excel template</Message.Item>
                                        </Message.List>
                                    </Message>
                                    {
                                        uploadError &&
                                        <Message negative>
                                            <Message.Header>Error in uploading gift data</Message.Header>
                                            <p>{errorMsg}</p>
                                            <p>{errorMsgData}</p>
                                        </Message>
                                    }
                                    <FilePond ref={ref => this.pond = ref}
                                        files={files}
                                        allowMultiple={false}
                                        maxFiles={1}
                                        onupdatefiles={fileItems => {
                                            // Set currently active file objects to this.state
                                            this.setState({
                                                files: fileItems.map(fileItem => fileItem.file),
                                                fileSelected: true
                                            });
                                        }}
                                        labelIdle='Drag and drop gifts list csv file or <span class="filepond--label-action">Browse</span>'
                                    >
                                    </FilePond>
                                </Modal.Content>

                                <Modal.Actions>
                                    <Button onClick={this.handleClose}>
                                        <Icon name='remove' /> Cancel
                                    </Button>
                                    {
                                        fileSelected &&
                                        <Button color='black' onClick={this.handleSubmit}>
                                            <Icon name='checkmark' /> Ok
                                    </Button>
                                    }

                                </Modal.Actions>
                            </Fragment>
                    }

                </Modal>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        giftsUpload: state.giftsUpload
    }
}

export default connect(mapStateToProps, {
    uploadGifts,
    uploadGiftsReset
})(UploadGifts);