import moment from 'moment';
export const loadGiftData = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'LOAD_GIFT_DATA_STARTED'})

        getFirestore().collection('gifts').get()
        .then(querySnapshot =>{
            const giftRecords = [];

            querySnapshot.forEach((doc)=>{
                const { 
                    ShipID,
                    CreatDate,
                    CompanyName,
                    OneHikID,
                    ShippingName,
                    ShippingAddress,
                    ShippingEmail,
                    ShippingPhone,
                    GiftName,
                    UnitPrice,
                    RedeemQTY,
                    claimed,
                    claimedTime,
                    claimedCustomerID,
                    claimedUserID,
                    totalClaimedValue,
                    totalRebateValue,
                    lastRequestCustomerID,
                    lastRequestUserID
                } = doc.data();

                const giftRecord = {
                    ShipID,
                    CreatedDate: CreatDate ? CreatDate : 'Not Available',
                    CompanyName: CompanyName ? CompanyName : 'Not Available',
                    OneHikID: OneHikID ? OneHikID : 'Not Available',
                    ShippingName: ShippingName ? ShippingName : 'Not Available',
                    ShippingAddress: ShippingAddress ? ShippingAddress : 'Not Available',
                    ShippingEmail: ShippingEmail ? ShippingEmail : 'Not Available',
                    ShippingPhone: ShippingPhone ? ShippingPhone : 'Not Available',
                    GiftName: GiftName ? GiftName : 'Not Available',
                    UnitPrice: UnitPrice ? UnitPrice : 'Not Available',
                    RedeemQTY: RedeemQTY ? RedeemQTY : 'Not Available',
                    ClaimStatus: claimed ? 'Claime Completed' : 'Not Claimed',
                    claimedTime: claimedTime ? moment(claimedTime.toDate()).format('MMMM Do YYYY, h:mm:ss a') : '',
                    claimedCustomerID: claimedCustomerID ? claimedCustomerID : '',
                    claimedUserID: claimedUserID ? claimedUserID : '',
                    totalClaimedValue: totalClaimedValue ?  totalClaimedValue : '',
                    totalRebateValue: totalRebateValue ? totalRebateValue : '',
                    lastRequestCustomerID: lastRequestCustomerID ? lastRequestCustomerID: '',
                    lastRequestUserID: lastRequestUserID ? lastRequestUserID : ''
                }

                return giftRecords.push(giftRecord);
            })

            dispatch({
                type: 'LOAD_GIFT_DATA_SUCCESS',
                payload: giftRecords
            })
        })
        .catch(error =>{
            dispatch({
                type: 'LOAD_GIFT_DATA_ERROR',
                payload: {
                    errorMsg: 'Error in loading gift data. Please contact the system administrator',
                    errorMsgData: error.toString()
                }
            })
        })


    }
}