import _ from 'lodash';
import moment from 'moment';

export const loadPendingApprovalUsers = () => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'LOAD_PENDING_APPROVAL_USERS_START' });

        const { countries, companyTypes, appList } = getState().config.config;

        getFirestore().collection('users').where('pendingApproval', '==', true).get()
            .then(querySnapshot => {
                const pendingApprovalUsers = [];

                querySnapshot.forEach(doc => {
                    const {
                        contactPerson,
                        contactNumber,
                        email,
                        purchaseCompanyName,
                        companyName,
                        companyType,
                        country,
                        registrationSource
                    } = doc.data();

                    const userRecord = {
                        id: doc.id,
                        contactPerson: contactPerson ? contactPerson : 'No data',
                        contactNumber: contactNumber ? contactNumber : 'No data',
                        email: email ? email : 'No data',
                        purchaseCompanyName: purchaseCompanyName ? purchaseCompanyName : 'No data',
                        companyName: companyName ? companyName : 'No data',
                        companyTypeText: companyType ? (_.find(companyTypes, { key: companyType }).text) : 'No data',
                        companyType: companyType ? companyType : 'No data',
                        countryText: country ? (_.find(countries, { key: country }).text) : 'No data',
                        country: country ? country : 'No data',
                        registrationSourceText: registrationSource ? (_.find(appList, { key: registrationSource }).text) : 'No data',
                        registrationSource: registrationSource ? registrationSource : 'No data'
                    }

                    return pendingApprovalUsers.push(userRecord);
                })

                dispatch({ type: 'LOAD_PENDING_APPROVAL_USERS_SUCCESS', payload: pendingApprovalUsers });
            })
            .catch(error => {
                dispatch({
                    type: 'LOAD_PENDING_APPROVAL_USERS_ERROR',
                    payload: {
                        errorMsg: 'Error in loading user data. Please contact the system administrator',
                        errorMsgData: error.toString()
                    }
                })
            })

    }
}

export const loadApprovedUsers = () => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'LOAD_APPROVED_USERS_START' })
        const { countries, companyTypes, appList } = getState().config.config;
        getFirestore().collection('users').where('approved', '==', true).get()
            .then(querySnapshot => {
                const approvedUsers = [];

                querySnapshot.forEach(doc => {
                    const {
                        contactPerson,
                        contactNumber,
                        email,
                        customerID,
                        companyName,
                        companyType,
                        country,
                        purchaseCompany,
                        registrationSource,
                        lastLoginCodeTime
                    } = doc.data();

                    const userRecord = {
                        id: doc.id,
                        contactPerson: contactPerson ? contactPerson : 'No data',
                        contactNumber: contactNumber? contactNumber : 'No data',
                        email: email ? email : 'No data',
                        customerID: customerID? customerID: 'No data',
                        companyName: companyName ? companyName : 'No data',
                        companyType: companyType ? companyType : 'No data',
                        companyTypeText: companyType ? (_.find(companyTypes, { key: companyType }).text) : 'No data',
                        purchaseCompany: purchaseCompany? purchaseCompany : 'No data',
                        country: country ? country : 'No data',
                        countryText: country ? (_.find(countries, { key: country }).text) : 'No data',
                        registrationSourceText: registrationSource ? (_.find(appList, { key: registrationSource }).text) : 'No data',
                        registrationSource: registrationSource ? registrationSource : 'No data',
                        lastLogin: moment(lastLoginCodeTime.toDate()).calendar()
                    }
                    return approvedUsers.push(userRecord);
                })

                dispatch({ type: 'LOAD_APPROVED_USERS_SUCCESS', payload: approvedUsers });
            })
            .catch(error => {
                dispatch({
                    type: 'LOAD_APPROVED_USERS_ERROR',
                    payload: {
                        errorMsg: 'Error in loading user data. Please contact the system administrator',
                        errorMsgData: error.toString()
                    }
                })
            })

    }
}



export const approveUser = (userData) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'APPROVE_USER_STARTED' })

        const { approveType } = userData;

        //Check approveType
        if (approveType === 'newCustomer') {
            //approveType is new customer. Creating a new customer
            const firestore = getFirestore();

            const { id, contactPerson, contactNumber, email, purchaseCompanyName, registrationSource, companyName, companyType, country, purchaseCompany, appPermission } = userData;

            firestore.collection('customers').add({
                companyName,
                companyType,
                purchaseCompany,
                country,
                appPermission,
                primaryContactPerson: contactPerson,
                primaryContactNumber: contactNumber,
                primaryEmail: email,
                primaryUserID: id
            }).then((result) => {
                //Successfully create the customer account
                //Updating user account
                firestore.collection('users').doc(id).update({
                    customerID: result.id,
                    contactPerson,
                    contactNumber,
                    email,
                    purchaseCompanyName,
                    companyName,
                    companyType,
                    country,
                    purchaseCompany,
                    registrationSource,
                    registered: true,
                    pendingApproval: false,
                    approved: true
                }).then(() => {
                    dispatch({ type: 'APPROVE_USER_SUCCESS' });
                }).catch(error => {
                    dispatch({
                        type: 'APPROVE_USER_ERROR',
                        payload: {
                            errorMsg: 'Error in updating user account',
                            errorMsgData: error.toString()
                        }
                    })
                })
            }).catch(error => {
                dispatch({
                    type: 'APPROVE_USER_ERROR',
                    payload: {
                        errorMsg: 'Error in creating new customer',
                        errorMsgData: error.toString()
                    }
                })
            })

        } else {
            //approve Type is add under exsisiting customer
            //Adding under exsisting customer
            const firestore = getFirestore();


            //Adding user under the selected company
            const { customerID, id } = userData;


            firestore.collection('customers').doc(customerID).update({
                users: getFirebase().firestore.FieldValue.arrayUnion(id)
            }).then(() => {
                //Successfully updated the customer account
                //Getting company details
                firestore.collection('customers').doc(customerID).get().then(doc => {
                    const { companyName, companyType, country, purchaseCompany } = doc.data();
                    const { contactPerson, contactNumber, email, purchaseCompanyName, registrationSource } = userData;

                    //Updating user account
                    firestore.collection('users').doc(id).update({
                        customerID,
                        contactPerson,
                        contactNumber,
                        email,
                        purchaseCompanyName,
                        companyName,
                        companyType,
                        country,
                        purchaseCompany: purchaseCompany ? purchaseCompany : '',
                        registrationSource,
                        registered: true,
                        pendingApproval: false,
                        approved: true
                    }).then(() => {
                        dispatch({ type: 'APPROVE_USER_SUCCESS' });
                    }).catch(error => {
                        dispatch({
                            type: 'APPROVE_USER_ERROR',
                            payload: {
                                errorMsg: 'Error in updating user account',
                                errorMsgData: error.toString()
                            }
                        })
                    })

                }).catch((error) => {
                    dispatch({
                        type: 'APPROVE_USER_ERROR',
                        payload: {
                            errorMsg: 'Error in getting customer data',
                            errorMsgData: error.toString()
                        }
                    })
                })



            }).catch(error => {
                dispatch({
                    type: 'APPROVE_USER_ERROR',
                    payload: {
                        errorMsg: 'Error in adding adding user under the selected customer',
                        errorMsgData: error.toString()
                    }
                })
            })
        }
    }
}