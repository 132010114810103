const initState = {
    loading: false,
    loadError: false,
    errorMsg: '',
    errorMsgData: '',
    giftData: []
}

const giftsListReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_GIFT_DATA_STARTED':
            return {
                ...initState,
                loading: true
            }
        case 'LOAD_GIFT_DATA_SUCCESS':
            return {
                ...initState,
                giftData: action.payload
            }
        case 'LOAD_GIFT_DATA_ERROR':
            const { errorMsg, errorMsgData } = action.payload;
            return {
                ...initState,
                loadError: true,
                errorMsg,
                errorMsgData
            }
        default:
            return state;
    }
}

export default giftsListReducer;