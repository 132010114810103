export const loadRemoteConfig = () => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({ type: 'LOAD_REMOTE_CONFIG_STARTED' })
        try {
            getFirestore().collection('config').doc('config').get()
                .then((doc) => {

                    const countries = JSON.parse(doc.data().countries);
                    const companyTypes = JSON.parse(doc.data().companyTypesForManagementPortal);
                    const appList = JSON.parse(doc.data().appList);
                    const malVerifyURLPrefix = doc.data().malVerifyURLPrefix;

                    const payload = {
                        countries,
                        companyTypes,
                        appList,
                        malVerifyURLPrefix
                    }

                    dispatch({ type: 'LOAD_REMOTE_CONFIG_COMPLETED', payload });

                }).catch(error => {
                    dispatch({
                        type: 'LOAD_REMOTE_CONFIG_ERROR',
                        payload: {
                            errorMsg: 'Error in getting configuration data. Please contact the system administrator',
                            errorMsgData: error.toString()
                        }
                    })
                })

        } catch (error) {
            dispatch({
                type: 'LOAD_REMOTE_CONFIG_ERROR',
                payload: {
                    errorMsg: 'Error in getting configuration data. Please contact the system administrator',
                    errorMsgData: error.toString()
                }
            })
        }

    }
}