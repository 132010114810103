import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Icon, List, Header, Message, Segment } from 'semantic-ui-react';
import { renderTextInput, renderSelectInput, renderTextArea } from '../../common/FormFields';
import { Field, reduxForm } from 'redux-form';
import QRCode from 'qrcode.react';

import { issueNewMal, closeNewMalModal } from '../../../store/actions/malVerifyActions';

import { loadCustomerData } from '../../../store/actions/customersActions';


class IssueMal extends Component {

    componentDidMount() {
        this.props.loadCustomerData();
    }


    onSubmit = (formValues) => {
        this.props.issueNewMal(formValues)
    }

    handleModalClose = () => {
        this.props.closeNewMalModal();
        this.props.handleClose();
    }

    render() {



        const {
            countries,
            malVerifyState,
            customers,
            malVerifyURLPrefix
        } = this.props;



        const { submitting, submitted, submitError, errorMsg, errorData, id } = malVerifyState;


        if (submitError) {
            return (
                <Fragment>
                    <Modal.Content>
                        <Message negative>
                            <Message.Header>{errorMsg}</Message.Header>
                            <p>{errorData}</p>
                        </Message>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleModalClose}>
                            <Icon name='remove' /> Close
                        </Button>

                    </Modal.Actions>
                </Fragment>
            )
        }

        if (submitted) {
            return (
                <Fragment>
                    <Modal.Content>
                        <Message success>
                            <Message.Header>Success</Message.Header>
                            <p>Data successfully submitted</p>
                        </Message>

                        <Segment textAlign='center'>
                            <p>Please copy the following QR code to the MAL</p>
                            <QRCode value={`${malVerifyURLPrefix}${id}`} />
                            <p>{`Verification ID: ${id}`}</p>
                        </Segment>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleModalClose}>
                            <Icon name='remove' /> Close
                        </Button>

                    </Modal.Actions>
                </Fragment>
            )
        }



        return (
            <Fragment>
                <Modal.Content>
                    <Segment loading={submitting}>

                        <Form autoComplete='off'>
                            <Header as='h4'>Partner Company Name</Header>
                            <List>
                                <List.Item>Select from the registered company list.</List.Item>
                                <List.Item>If the partner company not registered, register the company first from the Customers module</List.Item>
                            </List>

                            {
                                (customers.length === 0) &&
                                <Message negative>
                                    <Message.Header>No companies available in the database</Message.Header>
                                    <p>Please create company first from the Customers module</p>
                                </Message>
                            }

                            <Form.Group>
                                <Form.Field width={8}>
                                    <Field
                                        name='partnerCompany'
                                        component={renderSelectInput}
                                        otherProps={{
                                            fluid: true,
                                            placeholder: 'Partner Company Name',
                                            search: true,
                                            selection: true,
                                            options: customers
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group>
                                <Form.Field width={8}>
                                    <Field
                                        name='issueDate'
                                        component={renderTextInput}
                                        otherProps={{
                                            label: 'Letter Issuing Date',
                                            placeholder: 'Letter Issuing Date',
                                            type: 'date'
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Field
                                        name='tenderName'
                                        component={renderTextInput}
                                        otherProps={{
                                            label: 'Tender Name',
                                            placeholder: 'Tender Name'
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Field
                                        name='tenderRefNo'
                                        component={renderTextInput}
                                        otherProps={{
                                            label: 'Tender Reference Number',
                                            placeholder: 'Tender Reference Number'
                                        }}
                                    />
                                </Form.Field>

                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Field
                                        name='addressTo'
                                        component={renderTextArea}
                                        otherProps={{
                                            label: 'Address To',
                                            placeholder: 'Address To'
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>


                            <Form.Group>
                                <Form.Field width={8}>
                                    <Field
                                        name='country'
                                        component={renderSelectInput}
                                        otherProps={{
                                            fluid: true,
                                            label: 'Country',
                                            placeholder: 'Country',
                                            options: countries
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>



                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleModalClose}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='black' onClick={this.props.handleSubmit(this.onSubmit)}>
                        <Icon name='checkmark' /> Ok
                    </Button>
                </Modal.Actions>
            </Fragment>
        )
    }
}

const validate = (values) => {
    const errors = {}

    const {
        partnerCompany,
        issueDate,
        tenderName,
        tenderRefNo,
        addressTo,
        country
    } = values;

    if (!partnerCompany) { errors.partnerCompany = "Required" };
    if (!issueDate) { errors.issueDate = "Required" };
    if (!tenderName) { errors.tenderName = "Required" };
    if (!tenderRefNo) { errors.tenderRefNo = "Required" };
    if (!addressTo) { errors.addressTo = "Required" };
    if (!country) { errors.country = "Required" };


    return errors;
}

const mapStateToProps = (state) => {


    return {
        countries: state.config.config.countries,
        companyTypes: state.config.config.companyTypes,
        malVerifyState: state.malVerify,
        customers: state.customers.customerSearchData,
        malVerifyURLPrefix: state.config.config.malVerifyURLPrefix
    }
}

export default reduxForm({
    form: 'issueMal',
    validate
})(connect(mapStateToProps, {
    issueNewMal,
    closeNewMalModal,
    loadCustomerData
})(IssueMal));