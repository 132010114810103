import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom'

import SideNav from '../../common/SideNav';
import { epartnerNavConfig } from '../../../config/navConfig';

import GiftsList from './GiftsList';
import UploadGifts from './UploadGifts';

class Customers extends Component {
    render() {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Column width={2}>
                        <SideNav sideNavConfig={epartnerNavConfig}>
                            <UploadGifts />
                        </SideNav>
                    </Grid.Column>

                    <Grid.Column width={14}>
                        <Switch>
                            <Route path='/epartner' component={GiftsList} exact />
                        </Switch>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default Customers;
