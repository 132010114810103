import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'
import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

import authReduder from './authReducer';
import configReducer from './configReducer';
import giftUploadReducer from './giftUploadReducer';
import giftsListReducer from './giftsListReducer'
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import customersReducer from './customersReducer';
import malVerifyReducer from './malVerifyReducer';
import malListReducer from './malListReducer';

const rootReducer = combineReducers({
    auth: authReduder,
    config: configReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    form: reduxFormReducer,
    giftsUpload: giftUploadReducer,
    giftsList: giftsListReducer,
    users: userReducer,
    customer: customerReducer,
    customers: customersReducer,
    malVerify: malVerifyReducer,
    malList: malListReducer
})

export default rootReducer;