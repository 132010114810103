const initState = {
    loading: false,
    loadError: false,
    errorMsg: '',
    errorMsgData: '',
    pendingApprovalUsers: [],
    approvedUsers: [],
    userApproval: {
        approvalProcessing: false,
        approvalSuccess: false,
        approvalError: false,
        approvalErrorMsg: '',
        approvalErrorData: ''
    }
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_PENDING_APPROVAL_USERS_START':
            return {
                ...initState,
                loading: true
            }
        case 'LOAD_PENDING_APPROVAL_USERS_SUCCESS':
            return {
                ...initState,
                pendingApprovalUsers: action.payload
            }
        case 'LOAD_PENDING_APPROVAL_USERS_ERROR':
            return {
                ...initState,
                loadError: true,
                errorMsg: action.payload.errorMsg,
                errorMsgData: action.payload.errorMsgData
            }
        case 'LOAD_APPROVED_USERS_START':
            return {
                ...initState,
                loading: true
            }
        case 'LOAD_APPROVED_USERS_SUCCESS':
            return {
                ...initState,
                approvedUsers: action.payload
            }
        case 'LOAD_APPROVED_USERS_ERROR':
            return {
                ...initState,
                loadError: true,
                errorMsg: action.payload.errorMsg,
                errorMsgData: action.payload.errorMsgData
            }
        case 'APPROVE_USER_STARTED':
            return {
                ...state,
                userApproval: {
                    ...initState.userApproval,
                    approvalProcessing: true,
                }
            }
        case 'APPROVE_USER_SUCCESS':
            return {
                ...state,
                userApproval: {
                    ...initState.userApproval,
                    approvalSuccess: true,
                }
            }
        case 'APPROVE_USER_ERROR':
            return {
                ...state,
                userApproval: {
                    ...initState.userApproval,
                    approvalError: true,
                    approvalErrorMsg: action.payload.errorMsg,
                    approvalErrorData: action.payload.errorMsgData
                }
            }
        default:
            return state;
    }
}

export default userReducer;