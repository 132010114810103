const initState = {
    submitting: false,
    submitted: false,
    submitError: false,
    errorMsg: '',
    errorData: ''
}

const customerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_NEW_CUSTOMER_STARTED':
            return {
                ...initState,
                submitting: true
            }
        case 'CREATE_NEW_CUSTOMER_ERROR':
            const { errorMsg, errorData } = action.payload;
            return {
                ...initState,
                submitError: true,
                errorMsg,
                errorData
            }
        case 'CREATE_NEW_CUSTOMER_SUCCESS':
            return {
                ...initState,
                submitted: true
            }
        case 'CLOSE_CUSTOMER_MODAL':
            return initState;
        default:
            return state;
    }
}


export default customerReducer;