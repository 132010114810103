import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Icon, List, Header, Message, Segment } from 'semantic-ui-react';
import { renderTextInput, renderSelectInput, renderMultipleSelectInput } from '../../common/FormFields';
import { Field, reduxForm } from 'redux-form';

import { createNewCustomer, closeCustomerModal } from '../../../store/actions/customerActions';


class Customer extends Component {

    onSubmit = (formValues) => {
        this.props.createNewCustomer(formValues)
    }

    handleModalClose = () => {
        this.props.closeCustomerModal();
        this.props.handleClose();
    }

    getPurchaseCompanyOptions = () => {

        const {
            purchaseCompanySelectionForSSP,
            purchaseCompanySelectionForVASP,
            purchaseCompanySelectionForDSI,
            selectedCompanyType
        } = this.props;

        switch (selectedCompanyType) {
            case 'inst': return purchaseCompanySelectionForSSP;
            case 'dpp': return purchaseCompanySelectionForVASP;
            case 'si': return purchaseCompanySelectionForVASP;
            case 'dsi': return purchaseCompanySelectionForDSI;
            case 'nd': return purchaseCompanySelectionForDSI;
            default: return []
        }
    }

    render() {
        const {
            companyTypes,
            selectedCompanyType,
            countries,
            appList,
            customerState
        } = this.props;


        const { submitting, submitted, submitError, errorMsg, errorData } = customerState;



        const purchaseCompanyOptions = this.getPurchaseCompanyOptions();

        if (submitError) {
            return (
                <Fragment>
                    <Modal.Content>
                        <Message negative>
                            <Message.Header>{errorMsg}</Message.Header>
                            <p>{errorData}</p>
                        </Message>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleModalClose}>
                            <Icon name='remove' /> Close
                        </Button>

                    </Modal.Actions>
                </Fragment>
            )
        }

        if (submitted) {
            return (
                <Fragment>
                    <Modal.Content>
                        <Message success>
                            <Message.Header>Success</Message.Header>
                            <p>Data successfully submitted</p>
                        </Message>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleModalClose}>
                            <Icon name='remove' /> Close
                        </Button>

                    </Modal.Actions>
                </Fragment>
            )
        }



        return (
            <Fragment>
                <Modal.Content>
                    <Segment loading={submitting}>


                        <Form autoComplete='off'>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Field
                                        name='companyName'
                                        component={renderTextInput}
                                        otherProps={{
                                            label: 'Company Name',
                                            placeholder: 'Company Name'
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Field
                                        name='companyType'
                                        component={renderSelectInput}
                                        otherProps={{
                                            fluid: true,
                                            label: 'Company Type',
                                            placeholder: 'Company Type',
                                            options: companyTypes
                                        }}
                                    />
                                </Form.Field>

                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Field
                                        name='country'
                                        component={renderSelectInput}
                                        otherProps={{
                                            fluid: true,
                                            label: 'Country',
                                            placeholder: 'Country',
                                            options: countries
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Field
                                        name='appPermission'
                                        component={renderMultipleSelectInput}
                                        otherProps={{
                                            fluid: true,
                                            label: 'Permitted Apps',
                                            placeholder: 'Permitted Apps',
                                            multiple: true,
                                            selection: true,
                                            options: appList

                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>
                            {(selectedCompanyType !== null && selectedCompanyType !== 'co') &&
                                <Fragment>
                                    <Header as='h4'>Purchase From</Header>
                                    <List>
                                        <List.Item>Select from the registered company list.</List.Item>
                                        <List.Item>If the partner company not registered, register the company first</List.Item>
                                        <List.Item>Example: If the company type is SSP "purchase from company" should be DPP </List.Item>
                                    </List>

                                    {
                                        (purchaseCompanyOptions.length === 0) &&
                                        <Message negative>
                                            <Message.Header>No companies available in this category</Message.Header>
                                            <p>Please create the Purchase From Company first</p>
                                        </Message>
                                    }

                                    <Form.Group>
                                        <Form.Field width={8}>
                                            <Field
                                                name='purchaseCompany'
                                                component={renderSelectInput}
                                                otherProps={{
                                                    fluid: true,
                                                    placeholder: 'Purchase From',
                                                    search: true,
                                                    selection: true,
                                                    options: purchaseCompanyOptions
                                                }}
                                            />
                                        </Form.Field>
                                    </Form.Group>


                                </Fragment>
                            }

                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleModalClose}>
                        <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='black' onClick={this.props.handleSubmit(this.onSubmit)}>
                        <Icon name='checkmark' /> Ok
                    </Button>
                </Modal.Actions>
            </Fragment>
        )
    }
}

const validate = (values) => {
    const errors = {}

    const {
        companyName,
        companyType,
        country,
        appPermission,
        purchaseCompany
    } = values;

    if (!companyName) { errors.companyName = "Required" };
    if (!companyType) { errors.companyType = "Required" };
    if (!country) { errors.country = "Required" };
    if (!appPermission) {
        errors.appPermission = "Required"
    } else {
        if (appPermission.length === 0) {
            errors.appPermission = "Please select atleast one option"
        }
    }

    if (companyType !== 'co') {
        if (!purchaseCompany) { errors.purchaseCompany = "Required" };
    }


    return errors;
}

const mapStateToProps = (state) => {

    const getSelectedCompanyType = () => {
        if (state.form.createCustomer) {
            if (state.form.createCustomer.values) {
                if (state.form.createCustomer.values.companyType) {
                    return state.form.createCustomer.values.companyType;
                }
            }
        }
        return null;
    }

    return {
        purchaseCompanySelectionForSSP: state.customers.purchaseCompanySelectionForSSP,
        purchaseCompanySelectionForVASP: state.customers.purchaseCompanySelectionForVASP,
        purchaseCompanySelectionForDSI: state.customers.purchaseCompanySelectionForDSI,
        countries: state.config.config.countries,
        appList: state.config.config.appList,
        companyTypes: state.config.config.companyTypes,
        selectedCompanyType: getSelectedCompanyType(),
        customerState: state.customer
    }
}

export default reduxForm({
    form: 'createCustomer',
    validate
})(connect(mapStateToProps, {
    createNewCustomer,
    closeCustomerModal
})(Customer));