import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom'

import SideNav from '../../common/SideNav';
import { malVerifyNavConfig } from '../../../config/navConfig';

import MalList from './MalList';
import IssueMalModal from './IssueMalModal';

class MalVerify extends Component {
    render() {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Column stretched width={2}>
                        <SideNav sideNavConfig={malVerifyNavConfig}>
                            <IssueMalModal trigger='add' />
                        </SideNav>
                    </Grid.Column>

                    <Grid.Column stretched width={14}>
                        <Switch>
                            <Route path='/malverify' component={MalList} exact />
                        </Switch>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default MalVerify;
