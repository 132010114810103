import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class SideNav extends Component {
  state = { activeID: '' };

  handleItemClick = (e, { id }) => {
    this.setState({ activeID: id });
  };

  componentDidMount(){
    const { activeID } = this.props.sideNavConfig;
    this.setState({activeID})
  }

  render() {
    const { links } = this.props.sideNavConfig;
    const { activeID } = this.state;
    return (
      <Menu fluid vertical>
        {
          links.map(({ menuHeader, menuItems }) => {
            return (
              <Menu.Item key={menuHeader}>
                <Menu.Header>{menuHeader}</Menu.Header>
                <Menu.Menu>
                  {
                    menuItems.map((menuItem) => {
                      return (
                        <Menu.Item
                          onClick={this.handleItemClick}
                          name={menuItem.name}
                          key={menuItem.link}
                          active={activeID === menuItem.id}
                          as={Link}
                          to={menuItem.link}
                          id={menuItem.id}
                        />
                      )
                    })
                  }
                  {this.props.children}
                </Menu.Menu>
              </Menu.Item>
            )
          })
        }
      </Menu>
    )
  }
}

export default SideNav;