import React, { Component } from 'react';
import { Modal, Popup, Button, Menu } from 'semantic-ui-react';

import Customer from './Customer';

const initState = {
    modalOpen: false,
}

class CustomerModal extends Component {

    state = initState;

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.setState(initState);
    }

    getModalTrigger = (trigger) => {
        switch (trigger) {
            case 'edit':
                return (
                    <Popup content='Edit Customer' trigger={<Button icon='edit' onClick={this.handleOpen} />} />
                )
            case 'add':
                return (
                    <Menu.Item
                        name='Add New Customer'
                        onClick={this.handleOpen}
                    />
                )
            default:
                break;
        }
    }

    getModalTitle = (trigger, itemCode) => {
        switch (trigger) {
            case 'edit':
                return `Edit Customer: ${itemCode && itemCode}`;
            case 'add':
                return `Add New Customer`;
            default:
                break;
        }
    }

    render() {
        const { trigger, initialValues } = this.props;
        const { modalOpen } = this.state;

        return (
            <Modal
                trigger={this.getModalTrigger(trigger)}
                open={modalOpen}
                onClose={this.handleClose}
                size='small'
            >
                <Modal.Header>{this.getModalTitle(trigger, initialValues && initialValues.itemCode)}</Modal.Header>
                <Customer
                    handleClose={this.handleClose}
                    initialValues={initialValues}
                    trigger={trigger}
                />
            </Modal>
        )
    }
}


export default CustomerModal;