import csvToJSON from 'csv-file-to-json';
import _ from 'lodash';
import { validateEmail, fileToString, validateDataFields } from '../../components/common/CommonFunctions';

export const uploadGifts = (file) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        try {
            //Process start
            dispatch({ type: 'UPLOADING_GIFTS_START' });

            //Converting file to string
            const { output, success } = await fileToString(file);

            //Checking if the file conversion is successfull.
            if (success) {
                //Conversion is successfull
                //Checking if all the columns are avilable

                const requiredFields = ['ShipID', 'CreatDate', 'OneHikID', 'ShippingEmail', 'GiftName', 'UnitPrice', 'RedeemQTY'];

                //Validating if the imported file contains all mandatory fields.
                const { fieldAvailability, notAvailaleFields } = validateDataFields(output.split('\n')[0], requiredFields);

                if (fieldAvailability) {
                    //All required fields are available. 
                    //Converting  string to JSON

                    const giftData = csvToJSON({ data: output });

                    //Checking the validity of the array
                    if (giftData && Array.isArray(giftData) && giftData.length !== 0) {
                        //Removing the first record
                        giftData.shift();
                        
                        //Array is valid. Checking numbe of records
                        dispatch({ type: 'UPLOADING_GIFTS_COUNT_OBTAINED', payload: { count: giftData.length } })

                        //Validating and uploading records to the database start.
                        
                        //Handling Promises
                        const giftRecordPromises = []
                        var progressCount = 0;

                        giftData && giftData.map( async (giftRecord) => {
                            //Stripping empty values
                            const strippedGiftRecord = _.pickBy(giftRecord, _.identity);
                            
                            //Returning the promise
                            return giftRecordPromises.push(checkGiftRecordValidity(strippedGiftRecord, getFirestore).then((value)=>{
                                dispatch({type: 'UPLOADING_GIFTS_PROGRESS', payload: {count: ++progressCount, result: value}});
                            }))

                        })


                        Promise.all(giftRecordPromises)
                        .then(()=>{
                            dispatch({ type: 'UPLOADING_GIFTS_COMPLETED' })
                        })
                        
                    } else {
                        //No records available
                        dispatch({
                            type: 'UPLOADING_GIFTS_ERROR',
                            payload: {
                                errorMsg: 'Uploaded file doen not have any records'
                            }
                        })
                    }



                } else {
                    //All required fields are not available.
                    dispatch({
                        type: 'UPLOADING_GIFTS_ERROR',
                        payload: {
                            errorMsg: 'Following fields are not available. Please recheck your file.',
                            errorMsgData: notAvailaleFields.toString()
                        }
                    })
                }

            } else {
                //Conversion is not successfull
                dispatch({
                    type: 'UPLOADING_GIFTS_ERROR',
                    payload: {
                        errorMsg: 'Error in converting the uploaded file',
                        errorMsgData: output
                    }
                })
            }

        } catch (error) {
            dispatch({
                type: 'UPLOADING_GIFTS_ERROR',
                payload: {
                    errorMsg: 'Error in uploading gifts. Please contact the system administrator',
                    errorMsgData: error.toString()
                }
            })
        }

    }
}

export const uploadGiftsReset = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'UPLOADING_GIFTS_RESET'})
    }
} 

const checkGiftRecordValidity = (giftRecord, getFirestore) => {
    return new Promise((resolve, reject)=>{
        //Checking if all required data is available and email is valid.

        const { ShipID, ShippingEmail, GiftName, UnitPrice, RedeemQTY } = giftRecord;


        var recordValidity = true;
        var foundErrors = [];

        if (ShipID == null) {
            recordValidity = false;
            foundErrors.push('ShipID is not available');
        }
        if (ShippingEmail == null) {
            recordValidity = false;
            foundErrors.push('ShippingEmail is not available');
        }
        if (GiftName == null) {
            recordValidity = false;
            foundErrors.push('GiftName is not available');
        }
        if (UnitPrice == null) {
            recordValidity = false;
            foundErrors.push('UnitPrice is not available');
        }
        if (RedeemQTY == null) {
            recordValidity = false;
            foundErrors.push('RedeemQTY is not available');
        }
        if (ShippingEmail && !validateEmail(ShippingEmail)) {
            recordValidity = false;
            foundErrors.push('Shipping email is not valid');
        }

        if (recordValidity) {
            //Record is valid
            //Checking if the record is duplicated

            const gifts = getFirestore().collection('gifts');

            gifts.where('ShipID', '==', ShipID).get()
                .then(query => {
                    if (query.empty) {
                        //Not a duplicated record. Cleaning the object and adding data to the database
                        gifts.add(giftRecord)
                            .then(() => {
                                //Adding gift record success
                                resolve({
                                    success: true,
                                    ...giftRecord
                                })
                            })
                            .catch((error) => {
                                resolve({
                                    success: false,
                                    foundErrors: [error.toString()],
                                    ...giftRecord
                                })
                            })
                    } else {
                        resolve({
                            success: false,
                            foundErrors: ['Duplicated record'],
                            ...giftRecord
                        })
                    }
                })
                .catch((error) => {
                    resolve({
                        success: false,
                        foundErrors: [error.toString()],
                        ...giftRecord
                    })
                })

        } else {
            //Record is not valid. 
            resolve({
                success: false,
                foundErrors,
                ...giftRecord
            })
        }
    })
}