import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Switch, Route } from 'react-router-dom'


import SideNav from '../../common/SideNav';
import { usersNavConfig } from '../../../config/navConfig';

import Approved from './Approved';
import PendingApproval from './PendingApproval';

import ApproveUser from './ApproveUser';

class Users extends Component{
    render(){
        return(
            <Container fluid>
            <Grid>
                <Grid.Column stretched width={2}>
                    <SideNav sideNavConfig={usersNavConfig}>
                        <ApproveUser />
                    </SideNav>
                </Grid.Column>

                <Grid.Column stretched width={14}>
                    <Switch>
                        <Route path='/users' component={Approved} exact />
                        <Route path='/users/pending' component={PendingApproval} exact />
                    </Switch>
                </Grid.Column>
            </Grid>
        </Container>
        )
    }
}

export default Users;