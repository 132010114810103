import React, { Component, Fragment } from 'react';
import { Modal, Menu, Form, Button, Message, Grid, Header, Table, Radio, Dropdown, Segment, List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { approveUser } from '../../../store/actions/userActions';
import { loadCustomerData } from '../../../store/actions/customersActions';
import _ from 'lodash';

const initState = {
    modalOpen: false,
    userID: '',
    userSelected: false,
    selectedUser: '',
    error: false,
    errorMsg: '',
    approveType: null,
    customerID: null,
    purchaseCompany: null
}


class ApproveUser extends Component {

    state = initState;

    componentDidMount() {
        this.props.loadCustomerData();
    }

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.setState(initState);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    updateSelectedUserData = (e, { name, value }) => {
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                [name]: value
            }
        })
    }

    handleCustomerSelection = (e, { value }) => this.setState({ customerID: value })

    handlePurchaseCompanySelection = (e, { value }) => this.setState({ purchaseCompany: value })

    handleRadioSelection = (e, { value }) => this.setState({ approveType: value, error: false, errorMsg: '' });

    handleNext = () => {
        const { userSelected, approveType, selectedUser, customerID } = this.state;

        //Checking if the user is selected
        if (userSelected) {
            //User is selected
            //Checking all required data is selected



            //Checking if the approveType is selected
            if (approveType) {
                //Approve type is selected
                //Checking if the approve type is exsistingCustomer 
                if (approveType === 'exsistingCustomer') {
                    //Appprove type is exsistingCustomer 
                    //Checking if the customer is selected
                    if (customerID !== null) {
                        //Customer is selected. Getting user data
                        const { id, contactPerson, contactNumber, email, purchaseCompanyName, registrationSource } = selectedUser;

                        //Checking if all the user data is available
                        if (id && contactPerson && contactNumber && email && purchaseCompanyName && registrationSource) {
                            //All the user data available
                        } else {
                            //User data not available
                            this.setState({
                                error: true,
                                errorMsg: 'All the user data not available. Please recheck'
                            })
                        }

                        //Submitting form data
                        this.props.approveUser({
                            approveType,
                            customerID,
                            id,
                            contactPerson,
                            contactNumber,
                            email,
                            purchaseCompanyName,
                            registrationSource
                        });
                        this.setState({ error: false, errorMsg: '' });
                    } else {
                        //Customer is not selected. Adding error message
                        this.setState({
                            error: true,
                            errorMsg: 'Please select exsisting customer from the dropdown'
                        })

                    }
                } else {
                    //Approve type is new customer

                    //Checking if all required data is availale
                    const { purchaseCompany } = this.state;

                    const { id, contactPerson, contactNumber, email, purchaseCompanyName, registrationSource, companyName, companyType, country, appPermission } = selectedUser;


                    if (id && contactPerson && contactNumber && email && purchaseCompanyName && registrationSource && companyName && companyType && country && (appPermission && appPermission.length !== 0)) {
                        //All the required data are available

                        //Checking if companyType is not 'co'. Then needt validate purchase company as well.
                        if (companyType !== 'co') {
                            //companyType is not 'co'. Checking if purchaseCompany is available
                            if (purchaseCompany) {
                                //Purchase company is available. 
                                //Submitting form data
                                this.props.approveUser({
                                    approveType,
                                    id,
                                    contactPerson,
                                    contactNumber,
                                    email,
                                    purchaseCompanyName,
                                    registrationSource,
                                    companyName,
                                    companyType,
                                    country,
                                    purchaseCompany,
                                    appPermission
                                })
                                this.setState({ error: false, errorMsg: '' });
                            } else {
                                //purchaseCompany is not selected. Submitting an error.
                                this.setState({
                                    error: true,
                                    errorMsg: 'Please select the purchase from company'
                                })
                            }

                        } else {
                            //companyType is 'co'. No need to validate purchaseCompany. Going ahead.
                            //Submitting form data
                            this.props.approveUser({
                                approveType,
                                id,
                                contactPerson,
                                contactNumber,
                                email,
                                purchaseCompanyName,
                                registrationSource,
                                companyName,
                                companyType,
                                country,
                                purchaseCompany,
                                appPermission
                            })
                            this.setState({ error: false, errorMsg: '' });
                        }

                    } else {
                        //All required data not entered. Adding error message
                        this.setState({
                            error: true,
                            errorMsg: 'All the user data not available. Please recheck'
                        })
                    }

                }


            } else {
                //Approve type is not selected. Adding error message
                this.setState({
                    error: true,
                    errorMsg: 'Please select an option to approve user'
                })
            }

        } else {
            //User is not selected. Selecting the user.
            const { userID } = this.state;

            //Checking if the user ID is selected
            if (userID) {
                //User ID is entered
                //Getting the selected user data
                const { pendingApprovalUsers } = this.props.users;

                const selectedUser = _.find(pendingApprovalUsers, { id: userID })

                //Checking if the selected user is valid
                if (selectedUser) {
                    //Selected user is valid. Updating the selected user
                    this.setState({ selectedUser, userSelected: true, error: false, errorMsg: '' })
                } else {
                    //Selected user id is not valid. Updating error status
                    this.setState({
                        error: true,
                        errorMsg: 'Invalid User ID. Please re-check and enter'
                    })
                }


            } else {
                //User ID is not selected. Updating error status.
                this.setState({
                    error: true,
                    errorMsg: 'Please enter the User ID first'
                })
            }

        }

    }

    getPurchaseCompanyOptions = (companyType) => {

        const {
            purchaseCompanySelectionForSSP,
            purchaseCompanySelectionForVASP,
            purchaseCompanySelectionForDSI,
        } = this.props;

        switch (companyType) {
            case 'inst': return purchaseCompanySelectionForSSP;
            case 'dpp': return purchaseCompanySelectionForVASP;
            case 'si': return purchaseCompanySelectionForVASP;
            case 'dsi': return purchaseCompanySelectionForDSI;
            case 'nd': return purchaseCompanySelectionForDSI;
            default: return []
        }
    }

    render() {


        const { modalOpen, error, errorMsg, userSelected, selectedUser, approveType, customerID, userID, purchaseCompany } = this.state;

        const {
            customerSearchData,
            users,
            countries,
            companyTypes,
            appList
        } = this.props;

        const {
            approvalProcessing,
            approvalSuccess,
            approvalError,
            approvalErrorMsg,
            approvalErrorData
        } = users.userApproval

        const purchaseCompanyOptions = this.getPurchaseCompanyOptions(selectedUser.companyType);

        return (
            <Modal
                trigger={<Menu.Item
                    name='Approve User'
                    onClick={this.handleOpen}
                />}
                open={modalOpen}
                onClose={this.handleClose}
                size={(userSelected && !approvalSuccess) ? 'large' : 'mini'}
            >
                <Modal.Header>Approve User</Modal.Header>
                <Segment loading={approvalProcessing} basic>
                    <Modal.Content>
                        {
                            approvalError &&
                            <Message negative>
                                <Message.Header>{approvalErrorMsg}</Message.Header>
                                <p>{approvalErrorData}</p>
                            </Message>
                        }
                        {
                            error &&
                            <Message negative>
                                <Message.Header>{errorMsg}</Message.Header>
                            </Message>
                        }
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            label='Please enter the User ID'
                            placeholder='Enter User ID'
                            id="userID"
                            value={userID}
                            onChange={this.handleChange}
                        />

                        {
                            approvalSuccess &&
                            <Message success>
                                <Message.Header>User successfully approved</Message.Header>
                            </Message>
                        }
                        {
                            (userSelected && !approvalSuccess) &&
                            <Fragment>

                                <Message warning>
                                    <Message.Header>Before You Proceed</Message.Header>
                                    <Message.List>
                                        <Message.Item>Please check if there is a customer already existing in the Customers database</Message.Item>
                                        <Message.Item>If already exsisting you can add this user account under the same customer</Message.Item>
                                        <Message.Item>If not existing you can create a new Customer with this details and add this user account as the master account for Customer</Message.Item>
                                    </Message.List>
                                </Message>

                                <Header as='h4'>Please select an option</Header>
                                <Grid verticalAlign='middle'>
                                    <Grid.Column width={3}>
                                        <Radio
                                            label='Create new customer'
                                            name='radioGroup'
                                            value='newCustomer'
                                            checked={approveType === 'newCustomer'}
                                            onChange={this.handleRadioSelection}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Radio
                                            label='Add under existing customer'
                                            name='radioGroup'
                                            value='exsistingCustomer'
                                            checked={approveType === 'exsistingCustomer'}
                                            onChange={this.handleRadioSelection}
                                        />
                                    </Grid.Column>
                                    {
                                        (approveType === 'exsistingCustomer') &&
                                        <Grid.Column width={3}>
                                            <Dropdown
                                                placeholder='Select Customer'
                                                fluid
                                                search
                                                selection
                                                options={customerSearchData}
                                                value={customerID}
                                                onChange={this.handleCustomerSelection}
                                            />
                                        </Grid.Column>
                                    }
                                </Grid>
                                <Header as='h4'>Details</Header>
                                <Grid columns={2}>

                                    <Grid.Column>
                                        <Table celled striped>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan='2'>User Details</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>Contact Person</Table.Cell>
                                                    <Table.Cell>{selectedUser.contactPerson}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Contact Number</Table.Cell>
                                                    <Table.Cell>{selectedUser.contactNumber}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Email</Table.Cell>
                                                    <Table.Cell>{selectedUser.email}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Registration Source</Table.Cell>
                                                    <Table.Cell>{selectedUser.registrationSource}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>

                                    {
                                        approveType === 'newCustomer' &&
                                        <Grid.Column>
                                            <Table celled striped>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan='2'>Customer Details</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell>Company Name</Table.Cell>
                                                        <Table.Cell>
                                                            <Form.Input
                                                                placeholder='Enter Company Name'
                                                                name="companyName"
                                                                value={selectedUser.companyName}
                                                                onChange={this.updateSelectedUserData}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>Company Type</Table.Cell>
                                                        <Table.Cell>
                                                            <Dropdown
                                                                placeholder='Company Type'
                                                                name="companyType"
                                                                options={companyTypes}
                                                                selection
                                                                value={selectedUser.companyType}
                                                                onChange={this.updateSelectedUserData}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    {
                                                        selectedUser.type !== 'co' &&
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Purchase From
                                                                <List>
                                                                    <List.Item>Select from the registered company list.</List.Item>
                                                                    <List.Item>If the partner company not registered, register the company first</List.Item>
                                                                    <List.Item>Example: If the company type is SSP "purchase from company" should be DPP </List.Item>
                                                                    <List.Item>{`Customer has entered ${selectedUser.purchaseCompanyName}`}</List.Item>
                                                                </List>
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Dropdown
                                                                    placeholder='Select Customer'
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    required
                                                                    options={purchaseCompanyOptions}
                                                                    value={purchaseCompany}
                                                                    onChange={this.handlePurchaseCompanySelection}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    }

                                                    <Table.Row>
                                                        <Table.Cell>Country</Table.Cell>
                                                        <Table.Cell>
                                                            <Dropdown
                                                                placeholder='Country'
                                                                name="country"
                                                                options={countries}
                                                                selection
                                                                value={selectedUser.country}
                                                                onChange={this.updateSelectedUserData}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>App Permission</Table.Cell>
                                                        <Table.Cell>
                                                            <Dropdown
                                                                placeholder='Permitted Apps'
                                                                name="appPermission"
                                                                options={appList}
                                                                multiple
                                                                selection
                                                                value={selectedUser.appPermission ? selectedUser.appPermission : []}
                                                                onChange={this.updateSelectedUserData}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    }

                                </Grid>


                            </Fragment>
                        }
                    </Modal.Content>
                </Segment>
                <Modal.Actions>
                    {
                        approvalSuccess ?
                            <Button onClick={this.handleClose}>Close</Button>
                            :
                            <Fragment>
                                <Button onClick={this.handleClose}>Cancel</Button>
                                <Button onClick={this.handleNext} color='blue'>Next</Button>
                            </Fragment>
                    }

                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
        customerSearchData: state.customers.customerSearchData,
        purchaseCompanySelectionForSSP: state.customers.purchaseCompanySelectionForSSP,
        purchaseCompanySelectionForVASP: state.customers.purchaseCompanySelectionForVASP,
        purchaseCompanySelectionForDSI: state.customers.purchaseCompanySelectionForDSI,
        countries: state.config.config.countries,
        companyTypes: state.config.config.companyTypes,
        appList: state.config.config.appList
    }
}


export default connect(mapStateToProps, {
    approveUser,
    loadCustomerData
})(ApproveUser);