import _ from 'lodash';
export const loadCustomerData = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'LOAD_CUSTOMER_DATA_STARTED' });

        const {companyTypes, countries, appList} = getState().config.config;

        getFirestore().collection('customers').get()
            .then(querySnapshot => {
                const customerData = [];
                const customerSearchData = [];
                const purchaseCompanySelectionForSSP = []; //Only DPP partners should be in this list
                const purchaseCompanySelectionForVASP = []; //Only National distributors should be in this list.
                const purchaseCompanySelectionForDSI = []; //Only Country office should be in this list.

                querySnapshot.forEach(doc => {
                    const {
                        companyName,
                        companyType,
                        country,
                        purchaseCompany,
                        primaryContactPerson,
                        primaryContactNumber,
                        primaryEmail,
                        appPermission
                    } = doc.data();

                    const companyID = doc.id;

                    //Calculating app permission
                    const appPermissionText = [];

                    if(appPermission && appPermission.length !== 0){
                        appPermission.forEach((permission)=>{
                            appPermissionText.push(_.find(appList, { key: permission }).text)
                        })
                    }

                    const companyRecord = {
                        id: companyID,
                        companyName: companyName ? companyName : 'No data',
                        companyType: companyType ? companyType : 'No data',
                        companyTypeText: companyType ? (_.find(companyTypes, { key: companyType }).text) : 'No data',
                        country: country ? country : 'No data',
                        countryText: country ? (_.find(countries, { key: country }).text) : 'No data',
                        purchaseCompany: purchaseCompany ? purchaseCompany : 'No data',
                        primaryContactPerson: primaryContactPerson ? primaryContactPerson : '',
                        primaryContactNumber: primaryContactNumber ? primaryContactNumber : '',
                        primaryEmail: primaryEmail ? primaryEmail : '',
                        appPermission: appPermission ? appPermission : '',
                        appPermissionText
                    }
                    customerData.push(companyRecord);


                    customerSearchData.push({
                        key: companyID,
                        value: companyID,
                        text: companyName
                    })

                    if (companyType === 'dpp') {

                        purchaseCompanySelectionForSSP.push({
                            key: companyID,
                            value: companyID,
                            text: companyName
                        })
                    }

                    if (companyType === 'nd') {

                        purchaseCompanySelectionForVASP.push({
                            key: companyID,
                            value: companyID,
                            text: companyName
                        })
                    }

                    if (companyType === 'co') {

                        purchaseCompanySelectionForDSI.push({
                            key: companyID,
                            value: companyID,
                            text: companyName
                        })
                    }

                })

                dispatch({ type: 'LOAD_CUSTOMER_DATA_SUCCESS', payload: { customerData, customerSearchData, purchaseCompanySelectionForSSP, purchaseCompanySelectionForVASP, purchaseCompanySelectionForDSI } });
            })
            .catch(error => {
                dispatch({
                    type: 'LOAD_CUSTOMER_DATA_ERROR',
                    payload: {
                        errorMsg: 'Error in loading user data. Please contact the system administrator',
                        errorMsgData: error.toString()
                    }
                })
            })
    }
}