//Define all routes here.
//This can be used when programatic navigation is required via "history.push('path')"
export const allRoutes = {
    section: {
        create: '/section/create'
    }
}

//Define main navigation configuration here
export const mainNavConfig = {
    activeID: 'home',
    links: [
        { name: 'Home', link: '/', id: 'home' },
        { name: 'Customers', link: '/customers', id: 'customers' },
        { name: 'Access', link: '/access', id: 'access' },
        { name: 'Users', link: '/users', id: 'users' },
        { name: 'Hik-ePartner', link: '/epartner', id: 'epartner' },
        { name: 'MAL Verification', link: '/malverify', id: 'malverify' },
    ]
}

export const customersNavConfig = {
    activeID: 'customers',
    links: [
        {
            menuHeader: 'Customer Management',
            menuItems: [
                { name: 'All Customers', link: '/customers', id: 'customers' },
            ]
        }
    ]
}

export const epartnerNavConfig = {
    activeID: 'gifts',
    links: [
        {
            menuHeader: 'Epartner Management',
            menuItems: [
                { name: 'Gifts List', link: '/epartner', id: 'gifts' },
            ]
        }
    ]
}


export const usersNavConfig = {
    activeID: 'users',
    links: [
        {
            menuHeader: 'User Management',
            menuItems: [
                { name: 'All Approved Users', link: '/users', id: 'users' },
                { name: 'Pending Approval', link: '/users/pending', id: 'pending' },
            ]
        }
    ]
}


export const malVerifyNavConfig = {
    activeID: 'malverify',
    links: [
        {
            menuHeader: 'MAL Verifications',
            menuItems: [
                { name: 'Search Database', link: '/malverify', id: 'malverify' },
            ]
        }
    ]
}

