export const issueNewMal = (malData) => {
    return (dispatch, getState, { getFirestore}) => {
        dispatch({type: 'ISSUE_NEW_MAL_STARTED'})

        const firestore = getFirestore();

        firestore.collection('malverify')
        .add(malData)
        .then(({id})=>{
            dispatch({ 
                type: 'ISSUE_NEW_MAL_SUCCESS',
                payload: {
                    id
                }
            });

        })
        .catch((err)=>{
            dispatch({
                type: 'ISSUE_NEW_MAL_ERROR',
                payload: {
                    errorMsg: 'Error in adding MAL data to the database. Please contact system administrator',
                    errorData: err.toString()
                }
            })
        })
    }
}

export const closeNewMalModal = ()=> {
    return(dispatch, getState, {getFirestore}) => {
        dispatch({type: 'CLOSE_NEW_MAL_MODAL'})
    }
}


