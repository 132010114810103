const initState = {
    submitting: false,
    submitted: false,
    submitError: false,
    errorMsg: '',
    errorData: '',
    id: ''
}

const malVerifyReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ISSUE_NEW_MAL_STARTED':
            return {
                ...initState,
                submitting: true
            }
        case 'ISSUE_NEW_MAL_ERROR':
            const { errorMsg, errorData } = action.payload;
            return {
                ...initState,
                submitError: true,
                errorMsg,
                errorData
            }
        case 'ISSUE_NEW_MAL_SUCCESS':
            return {
                ...initState,
                submitted: true,
                id: action.payload.id
            }
        case 'CLOSE_NEW_MAL_MODAL':
            return initState;
        default:
            return state;
    }
}


export default malVerifyReducer;