const validateEmail = (email) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    return pattern.test(email) ? true : false
}

//FUNCTION TO CONVERT FILE TO A STRING
const fileToString = (file) => {
    return new Promise((resolve, reject) => {
        //Checking if the filetype is CSV
        if (file.type.match(/text.*/) || file.type.match('application/vnd.ms-excel')) {
            var reader = new FileReader();

            reader.onload = function (e) {
                var output = e.target.result;
                resolve({output,success: true})
            }

            reader.onerror = (e) => {
                reject({output: `File could not be read. Error code: ${e.target.error.code}`, success: false})
            }

            reader.readAsText(file);
        } else {
            //File type is not CSV. Rejecting 
            reject({output: `Please import the correct file type. (CSV)`, success: false})
        }
    })

}

//FUNCTION TO VALIDATE AVAILABLE FIELDS
const validateDataFields = (header, fields) => {
    var fieldAvailability = true;
    var notAvailaleFields = []

    fields.map(field => {
        if (!header.includes(field)) {
            fieldAvailability = false;
            notAvailaleFields.push(field);
        }
        return null;

    })
    return {fieldAvailability, notAvailaleFields};
}


export {
    validateEmail,
    fileToString,
    validateDataFields
}
