import React, { Component } from 'react';


class Access extends Component{
    render(){
        return(
            <div>Coming soon!</div>
        )
    }
}

export default Access;