const initState = {
    processing: false,
    totalCount: 0,
    processedCount: 0,
    failiureCount: 0,
    failedRecords: [],
    uploadCompleted: false,
    uploadError: false,
    errorMsg: '',
    errorMsgData: '',
}

const giftUploadReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPLOADING_GIFTS_START':
            return { 
                ...initState, 
                processing: true, 
            };
        case 'UPLOADING_GIFTS_COUNT_OBTAINED':
            return {
                ...state,
                totalCount: action.payload.count,
            };
        case 'UPLOADING_GIFTS_PROGRESS':

            const { count, result } = action.payload;
            
            if (!result.success) {
                return{
                    ...state,
                    failiureCount: state.failiureCount+1,
                    failedRecords: [...state.failedRecords, result],
                    processedCount: count
                }
            } else {
                return {...state, processedCount: count};
            }
        case 'UPLOADING_GIFTS_COMPLETED':
            return { ...state, processing: false, uploadCompleted: true}
        case 'UPLOADING_GIFTS_ERROR':
            return {
                ...initState,
                uploadError: true,
                errorMsg: action.payload.errorMsg,
                errorMsgData: action.payload.errorMsgData,
            };
        case 'UPLOADING_GIFTS_RESET':
            return initState;    
        default:
            return state;
    }
}

export default giftUploadReducer;