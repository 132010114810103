export const createNewCustomer = (customerData) => {
    return (dispatch, getState, { getFirestore }) => {
        dispatch({type: 'CREATE_NEW_CUSTOMER_STARTED'})
        const firestore = getFirestore();
        firestore.collection('customers')
            .add(customerData)
            .then(() => {
                dispatch({
                    type: 'CREATE_NEW_CUSTOMER_SUCCESS',
                    customerData
                });
            }).catch((err) => {
                dispatch({
                    type: 'CREATE_NEW_CUSTOMER_ERROR',
                    err
                });
            })
    }
}

export const closeCustomerModal = () => {
    return (dispatch, getState, { getFirestore}) => {
        dispatch({type: 'CLOSE_CUSTOMER_MODAL'});
    }
}

export const updateCustomer = (customerID, customerData) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('customers').doc(customerID)
            .set(customerData)
            .then(() => {
                dispatch({
                    type: 'UPDATE_CUSTOMER',
                    customerData
                });
            }).catch((err) => {
                dispatch({
                    type: 'UPDATE_CUSTOMER_ERROR',
                    err
                })
            })
    }
}

